import "@theatlantic/iframe-utility";

/*! Happiness Homework */

const cardCount = 8;

const deselectCard = function (targetCard) {
  document.getElementById(`card-${targetCard}`).classList.remove("selected");
};

const selectCard = function (targetCard) {
  document.getElementById(`card-${targetCard}`).classList.add("selected");
};

const nextCard = function (currentCard) {
  deselectCard(currentCard);
  selectCard(currentCard + 1);
};

const previousCard = function (currentCard) {
  deselectCard(currentCard);
  selectCard(currentCard - 1);
};

const getLastCanvasId = function () {
  return Array.from(document.getElementsByClassName("hw-canvas")).length;
};

const downloadCanvas = function () {
  let link = document.createElement("a");
  link.download = "how_to_build_a_happy_life.png";
  link.href = document
    .getElementById(`hw-canvas-${getLastCanvasId()}`)
    .toDataURL();
  link.click();
};

const addCanvasDownloadButtonEventListeners = function () {
  document
    .getElementById("hw-download")
    .addEventListener("click", downloadCanvas);
};

const audio = new Audio(
  "https://cdn.theatlantic.com/media/files/es_desert_parade_-_rand_aldo.mp3"
);

const playMusic = function () {
  if (audio.duration > 0 && !audio.paused) {
    audio.pause();
    Array.from(document.getElementsByClassName("hw-music")).forEach((btn) => {
      btn.innerHTML = "&#9835; turn on ambient tunes";
    });
  } else {
    audio.loop = true;
    audio.play();
    Array.from(document.getElementsByClassName("hw-music")).forEach((btn) => {
      btn.innerHTML = "&#9835; turn off ambient tunes";
    });
  }
};

const addPlayMusicButtonEventListeners = function () {
  Array.from(document.getElementsByClassName("hw-music")).forEach((btn) => {
    btn.addEventListener("click", () => {
      playMusic();
    });
  });
};

const canvasSteps = [];

const drawCanvas = function () {
  Array.from(document.getElementsByClassName("hw-canvas")).forEach((id) => {
    const canvas = document.getElementById(`hw-canvas-${id.dataset.id}`);
    const context = canvas.getContext("2d");

    // DOUBLE RESOLUTION
    canvas.width = canvas.width * 2;
    canvas.height = canvas.height * 2;
  });
  Array.from(document.getElementsByClassName("hw-display-canvas")).forEach(
    (id) => {
      const displayCanvas = document.getElementById(
        `hw-display-canvas-${id.dataset.step}`
      );
      if (!displayCanvas) return;

      const context = displayCanvas.getContext("2d");
      displayCanvas.width = displayCanvas.width * 2;
      displayCanvas.height = displayCanvas.height * 2;
    }
  );
  if ("parentIFrame" in window) {
    window.parentIFrame.size();
  }
};

const randomCoordinatesWithinBounds = function (canvas) {
  let x = Math.floor(Math.random() * canvas.width);
  let y = Math.floor(Math.random() * canvas.height);
  const xbleed = 60;
  const ybleed = xbleed * 2;

  if (x < xbleed) {
    x = xbleed;
  } else if (canvas.width - x < xbleed) {
    x = canvas.width - xbleed;
  }

  if (y < ybleed) {
    y = ybleed;
  } else if (canvas.height - y < ybleed) {
    y = canvas.height - ybleed;
  }

  return {
    x,
    y,
  };
};

const degreesToRadians = (degrees) => (degrees * Math.PI) / 180.0;

const randomRotation = function () {
  const coinflip = Math.floor(Math.random() * 3);
  const radians = degreesToRadians(Math.floor(Math.random() * 30));
  return coinflip > 1 ? radians : radians * -1;
};

const drawText = function (text, targetCanvas) {
  const canvas = document.getElementById(`hw-canvas-${targetCanvas}`);
  const context = canvas.getContext("2d");
  const coords = randomCoordinatesWithinBounds(canvas);
  const rotation = randomRotation();
  if (targetCanvas == 4) {
    const textStartX = canvas.width / 2;
    const textStartY = canvas.height / 2;

    const stroketypes = 8;
    const brushstrokes = [];
    for (let i = 1; i <= stroketypes; i++) {
      const brushSvg = document.getElementById(`hw-svg-${i}`);
      brushstrokes.push(brushSvg);
    }
    const textHeight = 36;
    const selectedSvg = brushstrokes[Math.floor(Math.random() * stroketypes)];
    selectedSvg.style.width = selectedSvg.style.width * 2;
    selectedSvg.style.height = selectedSvg.style.height * 2;

    context.drawImage(
      selectedSvg,
      textStartX - 453 / 2,
      textStartY - 138 / 2,
      453,
      138
    );

    context.font = `bold ${textHeight}px Logic Monospace`;
    context.textAlign = "center";
    context.fillText(text.toUpperCase(), textStartX, textStartY);
  } else {
    // 166, 128
    const textStartX = coords.x;
    const textStartY = coords.y;

    context.rotate(rotation);

    const stroketypes = 8;
    const brushstrokes = [];
    for (let i = 1; i <= stroketypes; i++) {
      const brushSvg = document.getElementById(`hw-svg-${i}`);
      brushstrokes.push(brushSvg);
    }
    const textHeight = 26;
    const selectedSvg = brushstrokes[Math.floor(Math.random() * stroketypes)];

    context.drawImage(
      selectedSvg,
      textStartX - selectedSvg.width / 2,
      textStartY - selectedSvg.height / 2
    );

    context.font = `bold ${textHeight}px Logic Monospace`;
    context.textAlign = "center";
    context.fillText(text.toUpperCase(), textStartX, textStartY);
    context.rotate(-rotation);
  }
};

const drawWordmark = function (targetCanvas) {
  const canvas = document.getElementById(`hw-canvas-${targetCanvas}`);
  const context = canvas.getContext("2d");
  const wordmark = document.getElementById("hw-wordmark");
  const xbleed = 10;
  const ybleed = 10;

  context.drawImage(
    wordmark,
    canvas.width - wordmark.width - xbleed,
    canvas.height - wordmark.height - ybleed
  );
};

const drawFinalCanvas = function () {
  const lastCanvas = document.getElementById(`hw-canvas-${getLastCanvasId()}`);
  const context = lastCanvas.getContext("2d");
  context.fillStyle = "#f6f2e9";
  context.fillRect(0, 0, lastCanvas.width, lastCanvas.height);
  Array.from(document.getElementsByClassName("hw-canvas")).forEach(
    (copiedCanvas) => {
      context.drawImage(copiedCanvas, 0, 0);
    }
  );
  drawWordmark(getLastCanvasId());
};

const drawDisplayOnlyCanvas = function (id) {
  const displayCanvas = document.getElementById(`hw-display-canvas-${id}`);
  if (!displayCanvas) return;
  const context = displayCanvas.getContext("2d");
  context.fillStyle = "#f6f2e9";
  context.fillRect(0, 0, displayCanvas.width, displayCanvas.height);
  Array.from(document.getElementsByClassName("hw-canvas")).forEach(
    (copiedCanvas) => {
      if (
        parseInt(copiedCanvas.dataset.step) <=
        parseInt(displayCanvas.dataset.step)
      ) {
        context.drawImage(copiedCanvas, 0, 0);
      }
    }
  );
};

const addTextInputEventListener = function () {
  Array.from(document.getElementsByClassName("hw-enter")).forEach(
    (enterButton) => {
      document
        .getElementById(`hw-enter-${enterButton.dataset.canvas}`)
        .addEventListener("click", (e) => {
          const inputElem = document.getElementById(
            `hw-input-${e.target.dataset.canvas}`
          );
          const text = inputElem.value.trim();
          // Don't do anything if value is empty or just spaces
          if (!text || text.length === 0) {
            return;
          }
          // Otherwise, add text to canvas
          drawText(text, e.target.dataset.canvas);
          canvasSteps.push(() => drawText(text, e.target.dataset.canvas));
          // Reset input value
          inputElem.value = "";
        });
    }
  );
};

const addNextButtonEventListeners = function () {
  Array.from(document.getElementsByClassName("hw-next")).forEach((btn) => {
    btn.addEventListener("click", () => {
      if (
        btn.dataset.card ==
        Array.from(document.getElementsByClassName("hw-card")).length - 2
      ) {
        drawFinalCanvas();
      }
      drawDisplayOnlyCanvas(parseInt(btn.dataset.card) + 1);
      nextCard(parseInt(btn.dataset.card));
      if ("parentIFrame" in window) {
        window.parentIFrame.size();
      }
    });
  });
};

/**
 *
 */
const addPreviousButtonEventListeners = () => {
  Array.from(document.getElementsByClassName("hw-previous")).forEach((btn) => {
    btn.addEventListener("click", () => {
      previousCard(parseInt(btn.dataset.card));
    });
  });
};

/**
 * Enables keyboard functionality
 * @param {Object} event - The javascript keydown event
 */
const handleKeyDowns = (event) => {
  switch (event.key) {
    // Enter or Return simulates "enter" click
    case "Enter":
    case "Return":
      const target = event.target || event.srcElement;
      // Only fire when user is typing in input boxes
      if (!target || !target.id.includes("hw-input-")) {
        return;
      }
      // Get the card and click "enter"
      let currentCard = target.id.split("-")[2];
      document.getElementById(`hw-enter-${currentCard}`).click();
      break;
    default:
      break;
  }
};

const addKeyboardEventListeners = () => {
  window.addEventListener("keydown", handleKeyDowns, true);
};

window.addEventListener("load", () => {
  dataLayer.push({
    event: "view interactive",
    event_action: "view interactive - How to Build a Happy Life",
    event_category: "story page",
    event_label: "How to Build a Happy Life",
  });

  addNextButtonEventListeners();
  addPreviousButtonEventListeners();
  addCanvasDownloadButtonEventListeners();
  addPlayMusicButtonEventListeners();
  addTextInputEventListener();

  // Enable keyboard functionality
  addKeyboardEventListeners();

  drawCanvas();
});
